import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {Layout} from "../components/Layout";
import { Link } from "gatsby";
import Button from '../components/buttons/button'
import ProductBanner from "../components/product_banners/product_banners";
import { SalWrapper } from "react-sal";
import Banner from "../imgs/bg_comoempezar.jpg";
import IframeVIdeo from "../components/iframe_video/iframe_video";
import Image from "../components/image/image";
/*import "../pages/pages.css";*/
import "./como-empezar.css";
import { Helmet } from "react-helmet";

let text = `Todo lo que necesitás saber para<br /> empezar a invertir en Balanz.`;
const pageTitle = '¿Cómo empezar a invertir? Todo lo que tenés que saber';
const pageDesc = 'Te contamos todo lo que necesitás saber para crear tu cuenta y empezar a invertir en Balanz. Es más simple de lo que pensás ¡Comenzá a invertir ahora!';
const EmpezarPage = () => {
    const page = (
        <>
            <Helmet>
                <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "@type": "VideoObject",
                            "name": "¿Cómo empezar a invertir?",
                            "description": "Invertir es más simple de lo que pensás.",
                            "thumbnailUrl": "https://www.youtube.com/watch?v=MTsM_JMwXWo",
                            "uploadDate": "2022-07-08",
                            "duration": "PT0M55S",
                            "contentUrl": "https://balanz.com/empezar-a-invertir/",
                            "embedUrl": "https://balanz.com/empezar-a-invertir/"
                        }
                    `}
                </script>
            </Helmet>
            <ProductBanner
                bannerContainerClassName="bannerCedear"
                bannerTrasparecy="bannerMask"
                productTitle="Cómo empezar a invertir"
                bannerImage={Banner}
                bannerImageClassName="cedearsBannerImage"
                textContainerClassName="textContainer"
                bannerText={text}
                bannerTextClassName="highlightsbold white"
                bannerImageMobile={Banner}
                mobile={false}
            />
            <SalWrapper>
            <section className="fullwidth primeros-pasos pb-0">
                <Container>
                    <Row className="pb-5 pt-3">
                        <h3 className="mt-2 pb-2 pb-md-3"
                            data-sal="slide-up"
                            data-sal-delay="500"
                            data-sal-duration="500"
                        ><span className="primary-color">Tus primeros pasos</span></h3>
                    </Row>
                    <Row className="pb-5 paso-1">
                        <p className="volanta"
                           data-sal="fade"
                           data-sal-delay="100"
                           data-sal-duration="200"
                        >Paso 1</p>
                        <h2
                            data-sal="fade"
                            data-sal-delay="200"
                            data-sal-duration="200"
                        >Crea tu usuario</h2>
                        <p className="base-text"
                           data-sal="fade"
                           data-sal-delay="300"
                           data-sal-duration="200"
                        >
                            Una vez abierta tu cuenta, descargá la app de Balanz.
                        </p>
                        <p className="base-text"
                           data-sal="fade"
                           data-sal-delay="320"
                           data-sal-duration="200"
                        >
                            Seleccioná <span className="highlightsbold">Crear/Activar usuario</span> para confirmar tus datos.
                            Recibirás un mail de bienvenida con un link para que generes tu usuario y contraseña.</p>
                        <p className="base-text"
                           data-sal="fade"
                           data-sal-delay="350"
                           data-sal-duration="200"
                        >La contraseña debe ser alfanumérica con al menos 8 caracteres, una letra mayúscula, una minúscula y un número.</p>

                    </Row>
                </Container>
                <div className="bg-lt-grey">
                    <Container>
                        <Row className="py-bigger paso-2">
                            <p className="volanta"
                               data-sal="fade"
                               data-sal-delay="100"
                               data-sal-duration="200"
                            >Paso 2</p>
                            <h2
                                data-sal="fade"
                                data-sal-delay="200"
                                data-sal-duration="200"
                            >Generá tu código de operación</h2>
                            <p className="base-text"
                               data-sal="fade"
                               data-sal-delay="300"
                               data-sal-duration="200"
                            >
                                El código de operación es el PIN de 4 dígitos que te permite operar en Balanz.
                            </p>
                            <p className="base-text"
                               data-sal="fade"
                               data-sal-delay="310"
                               data-sal-duration="200"
                            >
                                Ingresá desde la app en <strong>Perfil</strong>, seleccioná <strong>Mi usuario</strong> y luego <strong>Código de operación</strong>.
                            </p>
                            <p className="base-text"
                               data-sal="fade"
                               data-sal-delay="320"
                               data-sal-duration="200"
                            >
                                Si aún no registraste tu número de teléfono te pediremos que lo hagas en este paso
                            </p>
                            <p className="base-text"
                               data-sal="fade"
                               data-sal-delay="330"
                               data-sal-duration="200"
                            >
                                Completá los datos y te enviaremos un código de 6 dígitos por SMS o llamada para validar la operación.
                            </p>
                        </Row>
                    </Container>
                </div>
                <Container>
                    <Row className="py-bigger paso-3">
                        <p className="volanta"
                           data-sal="fade"
                           data-sal-delay="100"
                           data-sal-duration="200"
                        >Paso 3</p>
                        <h2
                            data-sal="fade"
                            data-sal-delay="200"
                            data-sal-duration="200"
                        >Transferí para empezar a invertir</h2>
                        <p className="base-text"
                           data-sal="fade"
                           data-sal-delay="300"
                           data-sal-duration="200"
                        >
                            Para comenzar a invertir en Fondos, Bonos, CEDEARs, Ons, Acciones y todos los activos del mercado, tendrás que tener dinero en tu cuenta Balanz.
                        </p>
                        <p className="base-text"
                           data-sal="fade"
                           data-sal-delay="320"
                           data-sal-duration="200"
                        >
                            Para esto deberás acceder a tu cuenta, buscar el botón “Ingresar” y copiar el Alias/CBU de nuestras cuentas bancarias a dónde vas a poder realizar las transferencias de dinero.
                            </p>
                        <p className="base-text"
                           data-sal="fade"
                           data-sal-delay="350"
                           data-sal-duration="200"
                        >
                            La transferencia tendrás que realizarla de un banco o billetera virtual a tu nombre (mismo CUIL/CUIT).
                            .
                            </p>
                            <div className="pt-3 pt-md-4 pt-lg-5 text-center"
                                 data-sal="fade"
                                 data-sal-delay="400"
                                 data-sal-duration="300"
                            >
                                <Link to="/inversiones">
                                <Button variant="secondary chevron-right" text="Elegí el instrumento ideal para vos" />
                                </Link>
                            </div>
                    </Row>
                </Container>
                <div className="bg-lt-grey">
                    <Container>
                        <Row className="py-bigger paso-4">
                            <p className="volanta"
                               data-sal="fade"
                               data-sal-delay="100"
                               data-sal-duration="200"
                            >Paso 4</p>
                            <h2
                                data-sal="fade"
                                data-sal-delay="200"
                                data-sal-duration="200"
                            >Realizá el test del inversor para conocer tu perfil</h2>
                            <p className="base-text"
                               data-sal="fade"
                               data-sal-delay="300"
                               data-sal-duration="200"
                            >
                                Descubrí qué tipo de inversor sos y conocé los productos que más se adecuan a tus objetivos y necesidades.
                            </p>
                            <p className="base-text"
                               data-sal="fade"
                               data-sal-delay="320"
                               data-sal-duration="200">
                                El test tiene 7 preguntas para poder analizar tu conocimiento del mercado financiero, objetivos, horizonte de la inversión, grado de riesgo aceptable, proporción del patrimonio a invertir y tu situación económica.
                            </p>
                            <div className="pt-3 pt-md-5 text-center"
                                 data-sal="fade"
                                 data-sal-delay="400"
                                 data-sal-duration="300"
                            >
                                <Link to="/test-del-inversor">
                                <Button variant="secondary" text="Completá el test"/>
                                </Link>
                            </div>
                        </Row>
                    </Container>
                </div>
            </section>
            <section className="fullwidth nuestra-app bg-primary">
                <Container>
                    <Row className="gx-5 py-3 py-md-4 py-lg-5 justify-content-between">
                        <Col xs={12} md={5} className="d-flex align-items-center justify-content-center">
                            <h3 className="ash2 m-0 pb-4 pb-md-0"
                                data-sal="fade"
                                data-sal-delay="100"
                                data-sal-duration="300"
                            ><span className="bigger-title lt-font white-color">Invertir es más simple <span className="highlightsbold secondary-color">de lo que pensás</span></span> </h3>
                        </Col>
                        <Col xs={12} md={7} className="d-lg-flex justify-content-lg-end align-items-center">
                            <IframeVIdeo
                                src="https://www.youtube.com/embed/MTsM_JMwXWo"
                                mobile={false}
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="fullwidth asesor"
                     data-sal="fade"
                     data-sal-delay="0"
                     data-sal-duration="300">
                <Container>
                    <Row className="d-flex justify-content-center g-0">
                        <Col xs={12}>
                            <p className="base-text">Podés ir a nuestra sección de <Link to="/preguntas-frecuentes"><strong>Preguntas Frecuentes</strong></Link> para conocer más detalles sobre la apertura de cuenta, operatoria y asesoramiento.</p>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="fullwidthbg appdownload2"
                     data-sal="fade"
                     data-sal-delay="0"
                     data-sal-duration="300"
            >
                <Container>
                    <Row className="d-flex align-items-stretch">
                        <Col>
                            <div className="section-content">
                                <h3 className="titulo primary-color"
                                    data-sal="fade"
                                    data-sal-delay="200"
                                    data-sal-duration="300"
                                ><span className="tit-45">Descargá nuestra App <br className="d-none d-md-inline" /><span className="highlightbold">y empezá a invertir.</span></span></h3>
                                <div className="cta"
                                     data-sal="fade"
                                     data-sal-delay="300"
                                     data-sal-duration="300"
                                >
                                    <a href="https://play.google.com/store/apps/details?id=com.mobile.balanz.droid&utm_source=Website&utm_medium=Banner&utm_campaign=Android" target="_blank">
                                    <Image
                                        className="store-img"
                                        src="PlayStore.png"
                                        alt="GStore"
                                    /></a>
                                    <a href="https://apps.apple.com/us/app/balanz/id1318206099?utm_source=Website&utm_medium=Banner&utm_campaign=IOS" target="_blank">
                                    <Image
                                        className="store-img"
                                        src="AppStore.png"
                                        alt="AStore"
                                    />
                                    </a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            </SalWrapper>
        </>
    );

    return (
        <main>
            <Layout title={pageTitle} description={pageDesc} childrem={page}  category={"empezar-a-invertir"}></Layout>
        </main>
    );
};

// Step 3: Export your component
export default EmpezarPage;